.patient-container {
  height: 100%;
  margin-right: 91px;
  margin-left: 91px;
  margin-top: 40px;
}

.patient-header {
  background: #FCFCFC;
  border-radius: 8px;
  height: 225px;
  padding: 24px;

  &__info {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.patient-name {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.03em;
  color: #1A1D1F;
}

.nav-tab {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
}