.button-font-style {
  font-family: 'Nunito' !important;
  font-style: normal !important;
  font-size: 15px !important;
  line-height: 24px !important;
  letter-spacing: -0.01em !important;
}

.button-font-style:hover {
  background-color: rgba(9, 107, 114, 0.6) !important;
}

.green-button {
  border: none !important;

  &:focus {
    box-shadow: none !important;
  }
}
