.tag-input-box {
  border-radius: 12px;
  border: none !important;
  box-shadow: none !important;
  flex: 1;
}

.tag-input-box > div > div > div > input {
  font-family: 'Nunito';
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #33383F;
}

.tag-input-box > div > div > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag-input-box > div > div > div > strong {
  margin: 0 4px;
  height: 100%;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #FCFCFC;
  background-color: #096B72;
  border-radius: 8px;
}

.tag-input-box > div > div > div > strong > svg {
  height: 24px;
  width: 24px;
}


.tag-count {
  font-family: 'Nunito';
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #33383F;
  display: flex;
  justify-content: center;
  align-items: center;

  &__max {
    color: #9A9FA5;
  }
}