.fc, table, th, tr, td {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.01em;
  color: #6F767E;
}

.fc-header-toolbar.fc-toolbar {
  background: #FCFCFC !important;
  margin-bottom: 0 !important;
  height: 72px;
}

.fc-day-today {
  background-color: rgba(181, 228, 202, 0.5) !important;
}

.fc-view {
  background-color: #FCFCFC;
}

.fc-toolbar-title {
  font-weight: 600;
  font-size: 20px !important;
}

.fc-button.fc-button-primary {
  background-color: transparent;
  border: 0;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 8px !important;
  border-color: transparent !important;
  font-weight: 600;
  font-size: 15px;

  &:hover {
    background: #efefef !important;
  }

  &.fc-button-active {
    background: #efefef !important;
  }
}

.fc-next-button.fc-button.fc-button-primary, .fc-prev-button.fc-button.fc-button-primary {
  border-radius: 44px !important;
  width: 44px !important;
  height: 44px !important;
  
  /* Neutral/03 */
  
  background: #EFEFEF;
}

.fc-timegrid-slot {
  height: 74px !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: none;
}

.fc-daygrid, .fc-timegrid {
  padding: 16px 28px;
}

.fc-toolbar-chunk > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fc-toolbar-title {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #1A1D1F;
}

.fc-col-header-cell, .fc-timegrid-axis {
  border: none !important;
  border-bottom: 1px solid #ddd !important;
}

.fc-col-header-cell-cushion {
  padding: 16px 4px !important;
}

.fc-scroller::-webkit-scrollbar {
  display: none;
}

.fc-scrollgrid-section-body > td {
  border-right: none;
  border-bottom: none;
}

.fc-scrollgrid-section-header > th {
  border-right: none;
}