.sidebar {
  grid-area: sidebar;
  width: 237px;
  background: #FCFCFC;
  padding: 24px;
}

.tab {
  padding: 12px;
}

.tab[aria-selected="true"] {
  background-color: #EFEFEF !important;
  color: #6F767E !important;
}

.tab:focus {
  box-shadow: none !important;
}

.tab-text {
  font-weight: 600;
  font-size: 15px;
  font-family: 'Nunito';
  color: #6F767E;
  text-decoration: none;
}